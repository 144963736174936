<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>东方美景预订平台</span>
      </template>
    </Header>
    <div class="main">
      <div class="pictures">
        <van-swipe style="height: 200px;" :autoplay="3000">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <van-image height="200" lazy-load fit="cover" :src="image" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="date">
        <van-row type="flex">
          <van-col span="8" style="text-align: center;">
            <div class="arrival">
              <div>{{ $t('checkin') }}</div>
              <div @click="showDate = true">{{ arrival | formatDate }}</div>
            </div>
          </van-col>
          <van-col span="8" style="display: flex; flex-direction: row;  align-items: center; justify-content: center;">
            <div class="nights">
              {{ nights }} {{ $t('nights') }}
            </div>
          </van-col>
          <van-col span="8" style="text-align: center;">
            <div class="departure">
              <div>{{ $t('checkout') }}</div>
              <div @click="showDate = true">{{ departure | formatDate }}</div>
            </div>
          </van-col>
        </van-row>
        <van-calendar
          v-model="showDate"
          type="range"
          :formatter="formatter"
          :min-date="calendarMinDate"
          :max-date="calendarMaxDate"
          :default-date="[arrival, departure]"
          @confirm="onConfirm"
        />
      </div>

      <van-divider />

      <div class="guests">
        <van-row type="flex">
          <van-col span="12">{{ $t('guests') }}</van-col>
          <van-col span="12" style="display: flex; flex-direction: row; justify-content: flex-end;">
            <van-stepper v-model="guests" integer :min="1" :max="10" />
          </van-col>
        </van-row>
      </div>

      <div class="search">
        <van-button type="warning" block style="border-radius: 6px;" @click="search">{{ $t('search') }}</van-button>
      </div>
    </div>

    <van-dialog
      v-model="showNotify"
      :title="$t('confirm')"
      show-cancel-button
      :show-confirm-button="nights >= 2"
      @confirm="goHotelList"
    >
      <van-cell-group>
        <van-cell
          :title="$t('checkin')"
          :value="arrival | formatDate"
        >
        </van-cell>
        <van-cell
          :title="$t('checkout')"
          :value="departure | formatDate"
        >
        </van-cell>
        <van-cell
          :title="$t('guests')"
          :value="guests"
        >
        </van-cell>
        <van-cell
          :title="$t('reservationNightsMessage')"
          :title-class="nights < 2 ? 'color-red' : ''"
        >
        </van-cell>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { Notify, Dialog } from 'vant'
import Header from '@/components/Header'
import { formatDate } from '@/filters'
import { getSystemDate } from '@/api/system'

export default {
  name: 'Search',
  components: {
    Header,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    const today = dayjs().startOf('date').toDate()
    return {
      showNotify: false,
      showDate: false,
      today,
      arrival: today,
      departure: today,
      guests: 1,
      minDate: today,
      maxDate: today,
      images: [
        `/server-static/banner01.png?v=${Date.now()}`,
        `/server-static/banner02.png?v=${Date.now()}`,
        `/server-static/banner03.png?v=${Date.now()}`
      ]
    }
  },
  async mounted () {
    // 获取可预订的范围
    try {
      const { data: today } = await getSystemDate({ format: 'DATE' })
      const res = await this.$axios.get('/invitation/reservation-dates')
      this.minDate = res.data.reservation_begin_date < today ? today : res.data.reservation_begin_date
      this.maxDate = res.data.reservation_end_date

      this.arrival = dayjs(this.minDate).toDate()
      const nextDay = dayjs(this.arrival).add(2, 'day').format('YYYY-MM-DD')
      this.departure = dayjs(nextDay <= this.maxDate ? nextDay : this.maxDate).toDate()
    } catch (e) {
      Notify(e.message)
    }
  },
  computed: {
    nights () {
      if (this.arrival && this.departure) {
        return dayjs(this.departure).diff(dayjs(this.arrival), 'day')
      }

      return 0
    },
    calendarMinDate () {
      return dayjs(this.minDate).toDate()
    },
    calendarMaxDate () {
      return dayjs(this.maxDate).toDate()
    }
  },
  methods: {
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = this.$t('in')
      } else if (day.type === 'end') {
        day.bottomInfo = this.$t('out')
      }

      return day
    },
    onConfirm (e) {
      this.arrival = e[0]
      this.departure = e[1]
      this.showDate = false
    },
    search () {
      this.showNotify = true
    },
    goHotelList () {
      if (this.nights >= 2) {
        this.$router.push({
          name: 'HotelList',
          params: { arrival: formatDate(this.arrival), departure: formatDate(this.departure), guests: this.guests }
        })
      }
    }
  }
}
</script>

<style lang="less">
.main {
  padding-top: 64px;
}

.pictures {
  padding-top: 1px;
}

.date {
  padding: 20px 10px;

  .nights {
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
    border-radius: 50%;
    color: #7C7C7C;
  }
}

.guests {
  .van-row {
    padding-top: 10px;
  }
  padding: 0 10px 10px 10px;
}

.search  {
  padding: 20px 10px;
}

.color-red {
  color: red;
}
</style>
